import {IDailyStrategyPNLReport} from "../../../apis/vitusApiTypes";
import _ from "lodash";
import {IDailyStrategyPNLReportWithChanges} from "./DailyStrategyPNLReport";
import moment, {Moment} from "moment";

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
})

const percentageFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
})

const dateFormatter = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
})

function formatCurrencyValue(value: number) {
    return currencyFormatter.format(value);
}

function formatPercentageValue(value: number) {
    return percentageFormatter.format(value);
}

function formatDate(value: Date) {
    return dateFormatter.format(value);
}

function addDailyChange(data: IDailyStrategyPNLReport[]): IDailyStrategyPNLReportWithChanges[] {
    const result: IDailyStrategyPNLReportWithChanges[] = [];
    const groupedData = _.groupBy(data, day => `${day.User}-${day.Exchange}-${day.SymbolId}`);

    for (const [, days] of Object.entries(groupedData)) {
        const sortedDays = _.orderBy(days, ['Date'], ['asc']);

        for (let i = 0; i < sortedDays.length; i++) {
            const day = sortedDays[i];

            if (i === 0) {
                // First day in the group
                result.push({
                    ...day,
                    PnlDailyChange: 0,
                    PnlNetDailyChange: 0,
                    PnlDailyChangePercent: 0,
                    PnlNetDailyChangePercent: 0
                });
                continue;
            }

            const previousDay = sortedDays[i - 1];

            const change = day.PnLTotal - previousDay.PnLTotal;
            const changePercentage = previousDay.PnLTotal !== 0
                ? (day.PnLTotal - previousDay.PnLTotal) / Math.abs(previousDay.PnLTotal)
                : 0;
            const netChange = day.PnLTotalNet - previousDay.PnLTotalNet;
            const netChangePercentage = previousDay.PnLTotalNet !== 0
                ? (day.PnLTotalNet - previousDay.PnLTotalNet) / Math.abs(previousDay.PnLTotalNet)
                : 0;

            result.push({
                ...day,
                PnlDailyChange: change,
                PnlNetDailyChange: netChange,
                PnlDailyChangePercent: changePercentage,
                PnlNetDailyChangePercent: netChangePercentage
            });
        }
    }

    return _.orderBy(result, ['Date'], ['desc']);
}

function getActiveContractsMap(data: IDailyStrategyPNLReportWithChanges[]): Map<string, boolean> {
    const contractMap: Map<string, boolean> = new Map();

    const uniqueSymbolIds = _.uniq(_.map(data, d => d.SymbolId));

    for (const symbolId of uniqueSymbolIds) {
        // We know the string will always be in the format "Dutch TTF Natural Gas Futures - TTF - Dec24"
        const symbolDate = moment(symbolId.split('-').pop(), "MMMYY");
        const isInFuture = isInFutureMonth(symbolDate);
        contractMap.set(symbolId, isInFuture);
    }

    return contractMap;
}

function isInFutureMonth(momentToCompare: Moment): boolean {
    const currentDate = moment();
    const currentMonth = currentDate.month();
    const currentYear = currentDate.year();
    const compareMonth = momentToCompare.month();
    const compareYear = momentToCompare.year();

    if (compareYear > currentYear) return true;
    if (compareYear < currentYear) return false;
    return compareMonth > currentMonth;
}

export type IGroupedDailyStrategyPNLReport = {
    Date: string;
    PnLTotal: number;
    PnLTotalNet: number;
    PnLDaily: number;
    PnLDailyNet: number;
    PnlCarryDaily: number;
    PnLCarryDailyNet: number;
    LongDaily?: number;
    ShortDaily?: number;
    LongTotal?: number;
    ShortTotal?: number;
    PnlDailyChange: number;
    PnlDailyChangePercent: number;
    PnlNetDailyChange: number;
    PnlNetDailyChangePercent: number;
}

function getShortDate(dateString: string): string {
    return dateString.split('T')[0];
}

function groupDailyPNLByDate(reports: IDailyStrategyPNLReportWithChanges[]) {
    if (!reports.length) return [];

    const bySymbol = _.groupBy(reports, 'SymbolId');

    const processReports = (
        date: string,
        dateReports: IDailyStrategyPNLReport[],
        latestReports: IDailyStrategyPNLReport[] = []
    ): IGroupedDailyStrategyPNLReport => ({
        Date: date,
        // Daily figures - only from actual date
        PnLDaily: dateReports.reduce((sum, r) => sum + r.PnLDaily, 0),
        PnLDailyNet: dateReports.reduce((sum, r) => sum + r.PnLDailyNet, 0),
        PnlCarryDaily: dateReports.reduce((sum, r) => sum + r.PnlCarryDaily, 0),
        PnLCarryDailyNet: dateReports.reduce((sum, r) => sum + r.PnLCarryDailyNet, 0),
        // Total figures - include latest data from missing symbols
        PnLTotal: dateReports.reduce((sum, r) => sum + r.PnLTotal, 0) +
            latestReports.reduce((sum, r) => sum + r.PnLTotal, 0),
        PnLTotalNet: dateReports.reduce((sum, r) => sum + r.PnLTotalNet, 0) +
            latestReports.reduce((sum, r) => sum + r.PnLTotalNet, 0),
        PnlDailyChange: 0,
        PnlNetDailyChange: 0,
        PnlNetDailyChangePercent: 0,
        PnlDailyChangePercent: 0,
    });

    const allDates = _.uniq(reports.map(r => getShortDate(r.Date)));

    // For each date, we need to:
    // 1. Get the reports for that date
    // 2. Find symbols that don't have data for that date and get their latest data
    const historicalResults = allDates
        .map(date => {
            // Get reports for this specific date
            const dayReports = reports.filter(r => getShortDate(r.Date) === date);

            // Get the symbols that have data for this date
            const symbolsWithData = new Set(dayReports.map(r => r.SymbolId));

            // For symbols without data for this date, get their latest data before this date
            const latestForMissingSymbols = Object.entries(bySymbol)
                .filter(([symbolId]) => !symbolsWithData.has(symbolId))
                .map(([x, symbolReports]) => {
                    return _.maxBy(
                        symbolReports.filter(r => getShortDate(r.Date) < date),
                        'Date'
                    );
                })
                .filter((report): report is IDailyStrategyPNLReportWithChanges => report !== undefined);

            return processReports(date, dayReports, latestForMissingSymbols);
        });

    return _.orderBy(calculatePercentageChanges(historicalResults), ['Date'], ['desc'])
}

function calculatePercentageChanges(groupedTrades: IGroupedDailyStrategyPNLReport[]): IGroupedDailyStrategyPNLReport[] {
    // Sort trades by date
    const sortedTrades = [...groupedTrades].sort((a, b) =>
        new Date(a.Date).getTime() - new Date(b.Date).getTime()
    );

    return sortedTrades.map((trade, index) => {
        if (index === 0) {
            // First day has 0% change
            return {
                ...trade,
                PnlDailyChange: 0,
                PnlNetDailyChange: 0,
                PnlDailyChangePercent: 0,
                PnlNetDailyChangePercent: 0
            };
        }

        const previousTrade = sortedTrades[index - 1];

        // Calculate percentage changes
        // Handle division by zero and edge cases

        const pnlDailyChange = trade.PnLTotal - previousTrade.PnLTotal;
        const pnlDailyChangePercent = previousTrade.PnLTotal === 0
            ? 0
            : (trade.PnLTotal - previousTrade.PnLTotal) / Math.abs(previousTrade.PnLTotal);

        const pnlNetDailyChange = trade.PnLTotalNet - previousTrade.PnLTotalNet;
        const pnlNetDailyChangePercent = previousTrade.PnLTotalNet === 0
            ? 0
            : (trade.PnLTotalNet - previousTrade.PnLTotalNet) / Math.abs(previousTrade.PnLTotalNet);

        return {
            ...trade,
            PnlDailyChange: pnlDailyChange,
            PnlNetDailyChange: pnlNetDailyChange,
            PnlDailyChangePercent: pnlDailyChangePercent,
            PnlNetDailyChangePercent: pnlNetDailyChangePercent,
        };
    });
}

function sortBySymbolId(a: any, b: any): number {
    const hubA = a.SymbolId.split('-')[0];
    const hubB = b.SymbolId.split('-')[0];
    if (hubA !== hubB) return hubA.localeCompare(hubB);

    // Sort by SymbolId using symbol date in descending order
    // We know the string will always be in the format "Dutch TTF Natural Gas Futures - TTF - Dec24"
    const symbolA = moment(a.SymbolId.split('-').pop(), 'MMMYY');
    const symbolB = moment(b.SymbolId.split('-').pop(), 'MMMYY');
    if (symbolA.isBefore(symbolB)) return 1;
    if (symbolB.isBefore(symbolA)) return -1;

    // Sort by Date in descending order
    const dateA = moment(a.Date);
    const dateB = moment(b.Date);
    return dateA.isBefore(dateB) ? 1 : -1;
}

export {currencyFormatter, percentageFormatter, dateFormatter, formatCurrencyValue, formatPercentageValue, formatDate, addDailyChange, getActiveContractsMap, groupDailyPNLByDate, sortBySymbolId};
