import {IDailyStrategyPNLReportWithChanges} from "../DailyStrategyPNLReport";
import {
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React, {useState} from "react";
import {
    formatDate,
    formatCurrencyValue,
    formatPercentageValue,
} from "../utils";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {ClassNameMap} from "@material-ui/styles";
import SummaryTablePagination from "./SummaryTablePagination";

interface SummaryTableProps {
    entries: IDailyStrategyPNLReportWithChanges[];
    activeContractsMap: Map<string, boolean>;
    onContractClick: (contract: IDailyStrategyPNLReportWithChanges) => void;
    showInactives: boolean;
    showPercentages: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
    },
    tableRow: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        "& .MuiTableCell-root": {
            fontSize: ".8rem", // Increased font size for the entire table
        },
    },
    expiredRow: {
        backgroundColor: "#e0e0e0",
        "&:hover": {
            backgroundColor: "#d5d5d5",
        },
    },
    changeValue: {
        display: "block",
    },
    changePercent: {
        display: "block",
        color: theme.palette.text.secondary,
    },
    positive: {
        color: theme.palette.success.main,
    },
    negative: {
        color: theme.palette.error.main,
    },
    tableHeader: {
        "& .MuiTableCell-head": {
            fontSize: ".9rem",
            fontWeight: "bold",
        },
    },
}));

const StyledTableCell = styled(TableCell)(({theme}) => ({
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
}));

const SummaryTable: React.FC<SummaryTableProps> = ({
                                                       entries,
                                                       activeContractsMap,
                                                       onContractClick,
                                                       showInactives,
                                                       showPercentages,
                                                   }) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const displayedEntries = entries.filter(
        (e) => showInactives || activeContractsMap.get(e.SymbolId)
    );
    const paginatedEntries = displayedEntries.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    return (
        <div className={classes.container}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow className={classes.tableHeader}>
                            <StyledTableCell>Symbol</StyledTableCell>
                            <StyledTableCell>Latest Trade Date</StyledTableCell>
                            <StyledTableCell align="right">PnL Total</StyledTableCell>
                            <StyledTableCell align="right">PnL Total (Net)</StyledTableCell>
                            <StyledTableCell align="right">Daily Change</StyledTableCell>
                            <StyledTableCell align="right">Daily Change (Net)</StyledTableCell>
                            <StyledTableCell align="right">PnL Daily</StyledTableCell>
                            <StyledTableCell align="right">PnL Daily (Net)</StyledTableCell>
                            <StyledTableCell align="right">PnL Carry Daily</StyledTableCell>
                            <StyledTableCell align="right">PnL Carry Daily (Net)</StyledTableCell>
                            <StyledTableCell align="right">Long Daily</StyledTableCell>
                            <StyledTableCell align="right">Short Daily</StyledTableCell>
                            <StyledTableCell align="right">Long Total</StyledTableCell>
                            <StyledTableCell align="right">Short Total</StyledTableCell>
                            <StyledTableCell align="right">Net Position</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedEntries.map((entry) => {
                            const isExpired = !activeContractsMap.get(entry.SymbolId);
                            const netPosition =
                                entry.LongTotal && entry.ShortTotal
                                    ? entry.LongTotal - entry.ShortTotal
                                    : null;

                            return (
                                <TableRow
                                    key={entry.SymbolId}
                                    hover
                                    onClick={() => onContractClick(entry)}
                                    className={`${classes.tableRow} ${
                                        isExpired ? classes.expiredRow : ""
                                    }`}
                                >
                                    <StyledTableCell>
                                        {entry.SymbolId}
                                        {isExpired && " (Expired)"}
                                    </StyledTableCell>
                                    <StyledTableCell>{formatDate(new Date(entry.Date))}</StyledTableCell>
                                    <StyledTableCell align="right">
                                        {renderCurrencyCell(classes,entry.PnLTotal)}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {renderCurrencyCell(classes,entry.PnLTotalNet)}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {renderCurrencyCell(
                                            classes,
                                            !showPercentages ? entry.PnlDailyChange : entry.PnlDailyChangePercent,
                                            showPercentages
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {renderCurrencyCell(
                                            classes,
                                            !showPercentages ? entry.PnlNetDailyChange : entry.PnlNetDailyChangePercent,
                                            showPercentages
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {renderCurrencyCell(classes, entry.PnLDaily)}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {renderCurrencyCell(classes, entry.PnLDailyNet)}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {renderCurrencyCell(classes, entry.PnlCarryDaily)}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {renderCurrencyCell(classes, entry.PnLCarryDailyNet)}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">{entry.LongDaily}</StyledTableCell>
                                    <StyledTableCell align="right">{entry.ShortDaily}</StyledTableCell>
                                    <StyledTableCell align="right">{entry.LongTotal}</StyledTableCell>
                                    <StyledTableCell align="right">{entry.ShortTotal}</StyledTableCell>
                                    <StyledTableCell align="right">
                                        {netPosition !== null ? netPosition : "-"}
                                    </StyledTableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <SummaryTablePagination
                count={displayedEntries.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};

function renderCurrencyCell(
    classes: ClassNameMap,
    value: number,
    percentage: boolean = false
) {
    return (
        <>
            <span
                className={`${classes.changeValue} ${
                    value > 0 ? classes.positive : value < 0 ? classes.negative : ""
                }`}
            >
            {percentage ? formatPercentageValue(value) : formatCurrencyValue(value)}
          </span>
        </>
    );
}

export default SummaryTable;
