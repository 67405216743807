import {ReportParams, ReportView} from "../../../system/ReportBase";
import {IDailyStrategyPNLReport} from "../../../apis/vitusApiTypes";
import {handleApiError} from "../../../utils/common";
import {createSpinner} from "../../../utils/spinnerManager";
import {getDailyStrategyPNLReports} from "../../../apis/vitusApi";
import AlertManager from "../../../utils/alertManager";
import messages from "../../../utils/messages";
import React, {ChangeEvent} from "react";
import ReportViewer from "../../ReportViewer/ReportViewer";
import Tab from '@material-ui/core/Tab';
import Tabs from "@material-ui/core/Tabs";
import {TabPanel} from "../../../components/VTabs/VTabs";
import {Grid} from "@material-ui/core";
import ContractsTab from "./ContractsTab";
import SummaryTab from "./Summary/SummaryTab";
import {addDailyChange, sortBySymbolId} from "./utils";
import TransactionHistoryDialog from "./TransactionHistory/TransactionHistoryDialog";

interface IState {
    data: IDailyStrategyPNLReportWithChanges[];
    selectedTab: number;
    selectedFilter: IFilter;
    selectedReport?: IDailyStrategyPNLReportWithChanges;
}

export interface IFilter {
    User?: string;
    Exchange?: string;
    SymbolId?: string;
    feesIncluded?: boolean;
}

const defaultFilter: IFilter = {
    User: "",
    Exchange: "",
    SymbolId: "",
    feesIncluded: false,
}

export interface IDailyStrategyPNLReportWithChanges extends IDailyStrategyPNLReport {
    PnlDailyChange: number;
    PnlDailyChangePercent: number;
    PnlNetDailyChange: number;
    PnlNetDailyChangePercent: number;
}

const fetchData = async (
    setData: (data: IDailyStrategyPNLReportWithChanges[]) => void,
    onError: (error: any) => void,
) => {
    const loadingSpinner = createSpinner();
    try {
        const response = await getDailyStrategyPNLReports();
        if (response.data.success) {
            const sortedData = response.data.success.sort(sortBySymbolId);
            const expandedData = addDailyChange(sortedData)
            setData(expandedData);
        }
        if (response.data.error) {
            AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED)
        }
    } catch (error) {
        onError(error);
    } finally {
        loadingSpinner.hide();
    }
};

class DailyStrategyPNLReport extends ReportView<{}, IState> {
    static params: ReportParams = new ReportParams(
        {
            reportKey: "DAILY_STRATEGY_PNL_REPORT",
            name: "HFT Daily PnL",
            path: '/dailyStrategyPNLReport',
            thumbnail: ""
        }
    )

    state: IState = {
        data: [],
        selectedTab: 1,
        selectedFilter: defaultFilter,
    }

    componentDidMount() {
        this.fetchData();
    }

    handleChange = (event: ChangeEvent<{}>, newTab: number) => {
        this.setState({selectedTab: newTab});
    };

    setSelectedFilter = (newFilter: IFilter) => this.setState({selectedFilter: newFilter})
    navigateToSelectedContract = (contract: IDailyStrategyPNLReportWithChanges) => this.setState({
        selectedTab: 0,
        selectedFilter: {
            User: contract.User,
            Exchange: contract.Exchange,
            SymbolId: contract.SymbolId,
        }
    })
    setSelectedReport = (contract: IDailyStrategyPNLReportWithChanges) => this.setState({
        selectedReport: contract
    });


    fetchData = () => {
        fetchData(
            (data) => {
                if (data.length) {
                    this.setState({
                        data
                    });
                }
            },
            handleApiError,
        );
    };

    render() {
        const {selectedTab, data} = this.state;

        return (
            <ReportViewer {...DailyStrategyPNLReport.params} onRefresh={() => this.fetchData()}
                          refreshButtonLabel='Refresh'>
                <Grid container spacing={1} justify="flex-start" alignItems="flex-start">
                    <Tabs
                        value={selectedTab}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        style={{paddingBottom: 10}}
                    >
                        <Tab label="Contracts"/>
                        <Tab label="Summary"/>
                    </Tabs>
                    <TabPanel value={selectedTab} index={0}>
                        <ContractsTab
                            data={data}
                            selectedFilter={this.state.selectedFilter}
                            setSelectedFilter={this.setSelectedFilter}
                            setSelectedReport={this.setSelectedReport}
                        />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <SummaryTab
                            data={data}
                            onContractClick={this.navigateToSelectedContract}
                        />
                    </TabPanel>
                    {this.state.selectedReport && <TransactionHistoryDialog
                      open={!!this.state.selectedReport}
                      onClose={() => this.setState({selectedReport: undefined})}
                      report={this.state.selectedReport}
                    />}
                </Grid>
            </ReportViewer>
        )
    }
}

export default DailyStrategyPNLReport;
