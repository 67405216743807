import React, {useEffect, useState} from 'react';
import {StrategyTransaction} from "../../../../apis/vitusApiTypes";
import moment from "moment";
import {createSpinner} from "../../../../utils/spinnerManager";
import { Close as CloseIcon } from '@material-ui/icons';
import {getTransactionHistoryForStrategy} from "../../../../apis/vitusApi";
import AlertManager from "../../../../utils/alertManager";
import messages from "../../../../utils/messages";
import {handleApiError} from "../../../../utils/common";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography
} from "@material-ui/core";
import {IDailyStrategyPNLReportWithChanges} from "../DailyStrategyPNLReport";
import {TransactionTable} from "./TransactionHistoryTable";
import {useTransactionHistoryStyles} from "./styles";
import DownloadCSV from "../DownloadCSV";

interface TransactionHistoryDialogProps {
    open: boolean;
    onClose: () => void;
    report: IDailyStrategyPNLReportWithChanges;
}

const SYMBOL_ID_PRODUCT_NAME_MAPPING: Record<string, string> = {
    'Dutch TTF Natural Gas Futures': 'Dutch TTF Gas Futures',
    'EUA Futures': 'EU Emissions Allowances',
}

const fetchData = async (
    report: IDailyStrategyPNLReportWithChanges,
    setData: (data: StrategyTransaction[]) => void,
    onError: (error: any) => void,
) => {
    const loadingSpinner = createSpinner();

    try {
        const startDate = moment(report.Date).format('YYYYMMDD');
        const endDate = moment(report.Date).format('YYYYMMDD');
        const rawProductName = report.SymbolId.split('-')[0].trim();
        const productName = SYMBOL_ID_PRODUCT_NAME_MAPPING[rawProductName];
        const contract = report.SymbolId.split('-').pop()?.trim();
        if (!contract) return;
        const response = await getTransactionHistoryForStrategy({
            start_date: startDate, end_date: endDate, product_name: productName, contract
        });

        if (response.data.success) {
            setData(response.data.success);
        }

        if (response.data.error) {
            AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED);
        }
    } catch (error) {
        onError(error);
    } finally {
        loadingSpinner.hide();
    }
}

const TransactionHistoryDialog: React.FC<TransactionHistoryDialogProps> = ({
    open,
    onClose,
    report
}) => {
    const classes = useTransactionHistoryStyles();
    const [data, setData] = useState<StrategyTransaction[]>([]);

    useEffect(() => {
        if (!report?.SymbolId) return;
        fetchData(report, setData, handleApiError);
    }, [report]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
            aria-labelledby="transaction-history-dialog"
            PaperProps={{ className: classes.dialogPaper }}
        >
            <DialogTitle disableTypography className={classes.dialogTitle}>
                <div />
                <Typography variant="h6" className={classes.titleText}>Transactions for {report.SymbolId} on {new Date(report.Date).toLocaleDateString()}</Typography>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                    size="small"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {data.length ? <TransactionTable transactions={data} /> : (
                    <div className={classes.loadingText}>
                        Loading transactions. This could take up to 30 seconds.
                    </div>
                )}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <DownloadCSV data={data} fileName={`${report.SymbolId}-${report.Date}`} />
            </DialogActions>
        </Dialog>
    );
}

export default TransactionHistoryDialog;
