import axios, { AxiosError } from 'axios';
import { store } from '../store/store';
import { initializeRecentReports, InitializeRecentReportsAction, SetUserAction, UpdateRecentReportsAction } from '../store/actions';
import {
    IBaseVitusResponse,
    ICustomChartListResponse,
    ICustomChartRequest,
    ICustomChartResponse,
    IDailyWithForecastPSDRequest,
    IDailyWithForecastPSDResponse,
    IDynamicDatasetsDataRequest,
    IDynamicDatasetsDataResponse,
    IDynamicDatasetsMetadataResponse,
    IGetUserResponse,
    ILoginRequest,
    ILoginResponse,
    IPowerSupplyDemandRequest,
    IPowerSupplyDemandResponse,
    IPSDSTaticDataResponse,
    ISaveCustomChartRequest,
    IGenericSaveObjectResponse,
    ISetFavouriteRequest,
    ISetFavouriteResponse,
    ISaveCustomReportRequest,
    ICustomReportRequest,
    ICustomReportResponse,
    IChartDataResponse,
    IBaseDatasetOptionsResponse,
    ISaveDatasetRequest,
    IDatasetRequest,
    IDatasetResponse,
    ISaveDatasetResponse,
    IMultiDatasetResponse,
    IReportsAndPermissionsResponse,
    IReportPermissionsRequest,
    ICacheDashboardResponse,
    ICacheRecordDetailResponse,
    ICacheRecordDetailsRequest,
    IDeleteCacheRecordRequest,
    IWarmUpSingleOperationRequest,
    IUsersAndRolesResponse,
    IUserDetailRequest,
    IUserDetailResponse,
    IEuropeGasDemandBalanceResponse,
    ICumulativeGasDemandResponse,
    IEuropeGasDemandInjectionSeasonResponse,
    IBaseBalanceRangeResponse,
    IGasFlowsInjectionResponse,
    IYoyChangesResponse,
    IBalanceNetFlowsResponse,
    ILngFlowsResponse,
    IForecastReportParamsResponse,
    IForecastReportDataRequest,
    IForecastReportDataResponse,
    IMonthlyEuropeSummaryResponse,
    ISaveEUMonthlyPredictionsRequest,
    IDeleteEUMonthlyPredictionRequest,
    ISaveEUMonthlyPredictionsResponse,
    ILongTermEuropeGasBalanceResponse,
    ISaveUserRequest,
    ISaveUserResponse,
    ICreateUserRequest,
    IDeleteUserRequest,
    IBalanceStorageResponse,
    IGasBalanceReportResponse,
    IWorkflowTasksResponse,
    IWorkflowTimeTableResponse,
    IWorkflowTimeTableRequest,
    IPriceSupplyDemandOptionsResponse,
    IEtlPriceSupplyDemandRequest,
    IEtlPriceSupplyDemandResponse,
    IFtrAnalysesPnLResponse,
    IFtrAnalysesPnLRequest,
    IFtrAnalysesPnlOptionsResponse,
    IContractOptionsResponse,
    IContractPricesRequest,
    IContractPricesResponse,
    IContractOptionsRequest,
    ILongTermGasBalancePredictionHistoryRequest,
    ILongTermGasBalancePredictionHistoryResponse,
    IDailyPNLReportResponse,
    ITransactionHistoryResponse,
    ITransactionHistoryRequest,
} from './vitusApiTypes';
import { handleApiError, redirectToLogin, toDateString } from '../utils/common';
import { getRecentReports } from '../utils/localStorageManager';
import { prepareCustomReports, prepareFavouriteReports } from '../utils/userSettings';
import { endpointNames } from '../utils/staticInfo';

const reportingRoles = [
    "VitusReportUser"
];

const vitusApi = axios.create({
    baseURL: process.env.REACT_APP_VITUS_API,
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: true,
    validateStatus: (status) => { return status === 200; }
});

export const dateTimeFormat = 'yyyy/MM/DD HH:mm';
export const dateFormat = 'yyyy/MM/DD';

export const login = async (body: ILoginRequest) => {
    return vitusApi.post<ILoginResponse>(endpointNames.login, body)
};

export const logout = async () => {
    return vitusApi.post<IBaseVitusResponse>(endpointNames.logout).then(response => {
        store.dispatch<SetUserAction>({ type: 'SET_USER', payload: { userChecked: true } });
    });
};

export const getUser = async () => {
    try {
        const response = await vitusApi.get<IGetUserResponse>(endpointNames.get_user);

        //Check if it everything is provided
        if (!response.data
            || !response.data.data
            || response.data.data.user_id === undefined
            || !response.data.data.user_name
            || !response.data.data.roles
            || response.data.data.roles.length <= 0
            || !response.data.data.roles.find(value => reportingRoles.includes(value))
            || !response.data.data.csrf)
            throw Error();

        const userAction = {
            userId: response.data.data.user_id,
            userName: response.data.data.user_name,
            roles: response.data.data.roles.filter(value => reportingRoles.includes(value)),
            reports: response.data.data.reports,
            customReports: prepareCustomReports(response.data.data.custom_reports),
            ownedCustomReports: prepareCustomReports(response.data.data.owned_custom_reports),
            favouriteReports: prepareFavouriteReports(response.data.data.favourite_reports),
            isLoggedIn: false,
            userChecked: true
        };

        vitusApi.defaults.headers.common['X-Vitus-Token'] = response.data.data.csrf;

        store.dispatch<SetUserAction>({ type: 'SET_USER', payload: userAction });

        const recentReports = getRecentReports(userAction.userName);

        if (recentReports)
            store.dispatch<InitializeRecentReportsAction>(
                initializeRecentReports(
                    recentReports
                        .filter((r: UpdateRecentReportsAction["payload"]) => userAction.reports.find(ar => ar === r.reportKey))
                )
            );

        return true;
    } catch (error) {
        store.dispatch<SetUserAction>({ type: 'SET_USER', payload: { userChecked: true } });

        handleApiError(error as AxiosError<any>);

        // If get_user fails handleApiError redirects to login only if the response is 401
        //but even if the error is not 401 we still must redirect to login because we dont have the user
        redirectToLogin();
        return false;
    }
}

export const getRealizedPowerSupplyDemand = async (body: IPowerSupplyDemandRequest) => {
    return vitusApi.post<IPowerSupplyDemandResponse>(endpointNames.getRealizedPowerSupplyDemand, body)
};

export const callSetFavouriteReport = async (body: ISetFavouriteRequest) => {
    return vitusApi.post<ISetFavouriteResponse>(endpointNames.setFavouriteReport, body)
};

export const getRealizedWithForecastPSD = async (body: IDailyWithForecastPSDRequest) => {
    return vitusApi.post<IDailyWithForecastPSDResponse>(endpointNames.getRealizedWithForecastPSD, body)
};

export const getPSDStaticData = async () => {
    return vitusApi.post<IPSDSTaticDataResponse>(endpointNames.getPSDStaticData)
};

export const getDynamicDatasetsMetadata = async () => {
    return vitusApi.post<IDynamicDatasetsMetadataResponse>(endpointNames.getDynamicDatasetsMetadata)
};

export const getDynamicDatasetsData = async (body: IDynamicDatasetsDataRequest) => {
    return vitusApi.post<IDynamicDatasetsDataResponse>(endpointNames.getDynamicDatasetsData, body)
};

export const getCustomCharts = async () => {
    return vitusApi.post<ICustomChartListResponse>(endpointNames.getCustomCharts)
};

export const getCustomChart = async (body: ICustomChartRequest) => {
    return vitusApi.post<ICustomChartResponse>(endpointNames.getCustomChart, body)
};

export const saveChart = async (body: ISaveCustomChartRequest) => {
    return vitusApi.post<IGenericSaveObjectResponse>(endpointNames.saveChart, body)
};

export const saveReport = async (body: ISaveCustomReportRequest) => {
    return vitusApi.post<IGenericSaveObjectResponse>(endpointNames.saveReport, body)
};

export const getCustomReport = async (body: ICustomReportRequest) => {
    return vitusApi.post<ICustomReportResponse>(endpointNames.getCustomReport, body)
};

export const getDynamicChartData = async (body: ICustomChartRequest) => {
    return vitusApi.post<IChartDataResponse>(endpointNames.getDynamicChartData, body)
};

export const getBaseDatasetOptions = async () => {
    return vitusApi.get<IBaseDatasetOptionsResponse>(endpointNames.getBaseDatasetOptions)
};

export const saveDataset = async (body: ISaveDatasetRequest) => {
    return vitusApi.post<ISaveDatasetResponse>(endpointNames.saveDataset, body)
};

export const getDataset = async (body: IDatasetRequest) => {
    return vitusApi.post<IDatasetResponse>(endpointNames.getDataset, body)
};

export const getAllDatasets = async () => {
    return vitusApi.post<IMultiDatasetResponse>(endpointNames.getAllDatasets)
};

export const getReportsAndPermissions = async () => {
    return vitusApi.post<IReportsAndPermissionsResponse>(endpointNames.getReportsAndPermissions)
};

export const saveReportPermissions = async (body: IReportPermissionsRequest) => {
    return vitusApi.post<IGenericSaveObjectResponse>(endpointNames.saveReportPermissions, body)
};

export const getCacheDashboard = async () => {
    return vitusApi.get<ICacheDashboardResponse>(endpointNames.getCacheDashboard)
};

export const getCacheRecordDetail = async (body: ICacheRecordDetailsRequest) => {
    return vitusApi.post<ICacheRecordDetailResponse>(endpointNames.getCacheRecordDetail, body)
};

export const deleteCacheRecord = async (body: IDeleteCacheRecordRequest) => {
    return vitusApi.post<IGenericSaveObjectResponse>(endpointNames.deleteCacheRecord, body)
};

export const warmUpAllOperations = async () => {
    return vitusApi.post<IGenericSaveObjectResponse>(endpointNames.warmUpAllOperations)
};

export const warmUpSingleOperation = async (body: IWarmUpSingleOperationRequest) => {
    return vitusApi.post<IGenericSaveObjectResponse>(endpointNames.warmUpSingleOperation, body)
};

export const getUsersAndRoles = async () => {
    return vitusApi.get<IUsersAndRolesResponse>(endpointNames.getUsersAndRoles)
};

export const getUserDetails = async (body: IUserDetailRequest) => {
    return vitusApi.post<IUserDetailResponse>(endpointNames.getUserDetails, body)
};
export const getEuropeGasDemandBalance = async () => {
    return vitusApi.post<IEuropeGasDemandBalanceResponse>(endpointNames.getEuropeGasDemandBalance)
};

export const getCumulativeGasDemand = async () => {
    return vitusApi.post<ICumulativeGasDemandResponse>(endpointNames.getCumulativeGasDemand)
};

export const getEuropeGasDemandInjectionSeason = async () => {
    return vitusApi.post<IEuropeGasDemandInjectionSeasonResponse>(endpointNames.getEuropeGasDemandInjectionSeason)
};

export const getGasBurn = async () => {
    return vitusApi.post<IBaseBalanceRangeResponse>(endpointNames.getGasBurn)
};

export const getGasFlowsInjection = async () => {
    return vitusApi.post<IGasFlowsInjectionResponse>(endpointNames.getGasFlowsInjection)
};

export const getYoYChanges = async () => {
    return vitusApi.post<IYoyChangesResponse>(endpointNames.getYoYChanges)
};

export const getBalanceStorages = async () => {
    return vitusApi.post<IBaseBalanceRangeResponse>(endpointNames.getBalanceStorages)
};

export const getBalanceEuropeProduction = async () => {
    return vitusApi.post<IBaseBalanceRangeResponse>(endpointNames.getEUProduction)
};

export const getBalanceNetFlows = async () => {
    return vitusApi.post<IBalanceNetFlowsResponse>(endpointNames.getBalanceNetFlows)
};

export const getBalanceStorage = async () => {
    return vitusApi.post<IBalanceStorageResponse>(endpointNames.getEUGasStorage)
};

export const getEuLng = async () => {
    return vitusApi.post<ILngFlowsResponse>(endpointNames.getEuLng)
};

export const getForecastReportParams = async () => {
    return vitusApi.post<IForecastReportParamsResponse>(endpointNames.getForecastReportParams)
};

export const getForecastReportData = async (body: IForecastReportDataRequest) => {
    return vitusApi.post<IForecastReportDataResponse>(endpointNames.getForecastReportData, body)
};

export const getEUMonthlyReport = async () => {
    return vitusApi.post<IMonthlyEuropeSummaryResponse>(endpointNames.getEUMonthlyReport)
};

export const saveEUMonthlyPredictions = async (body: ISaveEUMonthlyPredictionsRequest) => {
    return vitusApi.post<ISaveEUMonthlyPredictionsResponse>(endpointNames.saveEUMonthlyPredictions, body)
};

export const deleteEUMonthlyPrediction = async (body: IDeleteEUMonthlyPredictionRequest) => {
    return vitusApi.post<ISaveEUMonthlyPredictionsResponse>(endpointNames.deleteEUMonthlyPrediction, body)
};

export const getGasStorageReport = async () => {
    return vitusApi.post<ILongTermEuropeGasBalanceResponse>(endpointNames.getGasStorageReport)
};

export const saveUser = async (body: ISaveUserRequest) => {
    return vitusApi.post<ISaveUserResponse>(endpointNames.saveUser, body)
};

export const createUser = async (body: ICreateUserRequest) => {
    return vitusApi.post<ISaveUserResponse>(endpointNames.createUser, body)
};

export const deleteUser = async (body: IDeleteUserRequest) => {
    return vitusApi.post<ISaveUserResponse>(endpointNames.deleteUser, body)
};

export const getAllGasBalanceReport = async () => {
    return vitusApi.post<IGasBalanceReportResponse>(endpointNames.getAllGasBalanceReport)
};

export const getWorkflowTasks = async () => {
    return vitusApi.get<IWorkflowTasksResponse>(endpointNames.getWorkflowTasks)
};

export const getWorkflowTimeTable = async (body: IWorkflowTimeTableRequest) => {
    let { min_start_date, max_start_date, min_end_date, max_end_date, ...rest } = body;

    const modifiedFilter: { [key: string]: any } = rest;

    if (body.min_start_date)
        modifiedFilter["min_start_date"] = toDateString(dateTimeFormat, body.min_start_date)
    if (body.max_start_date)
        modifiedFilter["max_start_date"] = toDateString(dateTimeFormat, body.max_start_date)
    if (body.min_end_date)
        modifiedFilter["min_end_date"] = toDateString(dateTimeFormat, body.min_end_date)
    if (body.max_end_date)
        modifiedFilter["max_end_date"] = toDateString(dateTimeFormat, body.max_end_date)

    return vitusApi.post<IWorkflowTimeTableResponse>(endpointNames.getWorkflowTimeTable, modifiedFilter)
};


export const getPriceSupplyDemandOptions = async () => {
    return vitusApi.get<IPriceSupplyDemandOptionsResponse>(endpointNames.getPriceSupplyDemandOptions)
};

export const getEtlPriceSupplyDemandData = async (body: IEtlPriceSupplyDemandRequest) => {
    return vitusApi.post<IEtlPriceSupplyDemandResponse>(endpointNames.getEtlPriceSupplyDemandData, body)
};

export const getFtrAnalysesData = async (body: IFtrAnalysesPnLRequest) => {
    let { min_date_time, max_date_time, ...rest } = body;

    const modifiedFilter: { [key: string]: any } = rest;

    if (body.min_date_time)
        modifiedFilter["min_date_time"] = toDateString(dateTimeFormat, body.min_date_time)

    if (body.max_date_time)
        modifiedFilter["max_date_time"] = toDateString(dateTimeFormat, body.max_date_time)

    return vitusApi.post<IFtrAnalysesPnLResponse>(endpointNames.getFtrAnalysesData, modifiedFilter)
};

export const getFtrAnalysesPnlOptionsAndPortfolio = async () => {
    return vitusApi.get<IFtrAnalysesPnlOptionsResponse>(endpointNames.getFtrAnalysesPnlOptionsAndPortfolio)
};

export const getContractOptions = async (params?: IContractOptionsRequest) => {
    return vitusApi.get<IContractOptionsResponse>(endpointNames.getContractOptions, { params })
};

export const getContractPricesData = async (body: IContractPricesRequest) => {
    let { min_date_time, max_date_time, ...rest } = body;

    const modifiedFilter: { [key: string]: any } = rest;

    if (body.min_date_time)
        modifiedFilter["min_date_time"] = toDateString(dateFormat, body.min_date_time)

    if (body.max_date_time)
        modifiedFilter["max_date_time"] = toDateString(dateFormat, body.max_date_time)

    return vitusApi.post<IContractPricesResponse>(endpointNames.getContractPricesData, modifiedFilter)
};

export const getLongTermGasBalancePredictionHistory = async (body: ILongTermGasBalancePredictionHistoryRequest) => {
    return vitusApi.post<ILongTermGasBalancePredictionHistoryResponse>(endpointNames.getLongTermGasBalancePredictionHistory, body)
};

export const getDailyStrategyPNLReports = async () => {
    return vitusApi.get<IDailyPNLReportResponse>(endpointNames.getDailyStrategyPNLReports);
}

export const getTransactionHistoryForStrategy = async (params: ITransactionHistoryRequest) => {
    return vitusApi.get<ITransactionHistoryResponse>(endpointNames.getStrategyTransactions, {
        params
    });
}
