import React from 'react';
import {makeStyles, styled, TableCell, TableRow} from "@material-ui/core";
import {StrategyTransaction} from "../../../../apis/vitusApiTypes";

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const StyledTableCell = styled(TableCell)(({theme}) => ({
    fontSize: 13,
}));

const useStyles = makeStyles((theme) => ({
    Bought: {
        fontWeight: 'bold',
        color: theme.palette.success.main,
    },
    Sold: {
        fontWeight: 'bold',
        color: theme.palette.error.main,
    },
}));

interface Props {
    transaction: StrategyTransaction;
    showIdColumns: boolean;
}

export const TransactionTableRow: React.FC<Props> = ({transaction, showIdColumns}) => {
    const classes = useStyles();

    return (
        <StyledTableRow>
            <StyledTableCell>
                {new Date(transaction.deal_date).toLocaleString()}
            </StyledTableCell>
            <StyledTableCell>{transaction.product}</StyledTableCell>
            <StyledTableCell>{transaction.hub}</StyledTableCell>
            <StyledTableCell>{transaction.contract}</StyledTableCell>
            <StyledTableCell>
                <span className={classes[transaction.bought_sold]}>
                    {transaction.bought_sold}
                </span>
            </StyledTableCell>
            <StyledTableCell align="right">{transaction.total_quantity}</StyledTableCell>
            <StyledTableCell align="right">
                {transaction.price.toFixed(3)}
            </StyledTableCell>
            <StyledTableCell>{transaction.price_units}</StyledTableCell>
            {showIdColumns && <StyledTableCell>{transaction.deal_id}</StyledTableCell>}
            {showIdColumns && <StyledTableCell>{transaction.leg_id}</StyledTableCell>}
            {showIdColumns && <StyledTableCell>{transaction.orig_order_id}</StyledTableCell>}
        </StyledTableRow>
    );
};