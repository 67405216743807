import React from 'react';
import {
    makeStyles,
    Theme,
    TablePagination as MuiTablePagination,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginLeft: theme.spacing(2),
    },
}));

interface TablePaginationProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: unknown, newPage: number) => void;
    onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SummaryTablePagination: React.FC<TablePaginationProps> =
    ({
         count,
         page,
         rowsPerPage,
         onPageChange,
         onRowsPerPageChange,
     }) => {
        const classes = useStyles();

        return (
            <MuiTablePagination
                className={classes.root}
                component="div"
                count={count}
                page={page}
                onChangePage={onPageChange}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={onRowsPerPageChange}
                rowsPerPageOptions={[5, 10, 25, 50]}
            />
        );
    };

export default SummaryTablePagination;