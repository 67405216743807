import React from 'react';
import {styled, TableCell, TableHead, TableRow, TableSortLabel} from "@material-ui/core";
import {StrategyTransaction} from "../../../../apis/vitusApiTypes";


const HeaderCell = styled(TableCell)(({theme}) => ({
    fontWeight: 600,
    backgroundColor: theme.palette.grey[100],
}));

interface Column {
    id: string;
    label: string;
    align?: 'right' | 'left' | 'center';
}

interface Props {
    order: 'asc' | 'desc';
    orderBy: string;
    onRequestSort: (property: keyof StrategyTransaction) => void;
    showIdColumns: boolean;
}

const getColumns = (showIdColumns: boolean): Column[] => {
    const baseColumns: Column[] = [
        {id: 'deal_date', label: 'Deal Date (Local Time)'},
        {id: 'product', label: 'Product'},
        {id: 'hub', label: 'Hub'},
        {id: 'contract', label: 'Contract'},
        {id: 'bought_sold', label: 'Type'},
        {id: 'total_quantity', label: 'Quantity', align: 'right'},
        {id: 'price', label: 'Price', align: 'right'},
        {id: 'price_units', label: 'Units'}
    ];

    if (!showIdColumns) return baseColumns;

    return [
        ...baseColumns,
        {id: 'deal_id', label: 'Deal ID'},
        {id: 'leg_id', label: 'Leg ID'},
        {id: 'orig_order_id', label: 'Orig ID'}
    ];
}

export const TransactionTableHeader: React.FC<Props> =
    ({
         order,
         orderBy,
         onRequestSort,
         showIdColumns
     }) => {
        return (
            <TableHead>
                <TableRow>
                    {getColumns(showIdColumns).map((column) => (
                        <HeaderCell
                            key={column.id}
                            align={column.align || 'left'}
                            sortDirection={orderBy === column.id ? order : false}
                            style={{fontSize: 14}}
                        >
                            <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order : 'asc'}
                                onClick={() => onRequestSort(column.id as keyof StrategyTransaction)}
                            >
                                {column.label}
                            </TableSortLabel>
                        </HeaderCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };
