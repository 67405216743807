import React, {useState} from 'react';
import {TransactionTableHeader} from './TransactionTableHeader';
import {TransactionTableRow} from './TransactionTableRow';
import {FormControlLabel, Table, TableBody, TableContainer} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {StrategyTransaction} from "../../../../apis/vitusApiTypes";
import {useTransactionHistoryStyles} from "./styles";
import Checkbox from "@material-ui/core/Checkbox";


interface Props {
    transactions: StrategyTransaction[];
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

function getComparator<T>(
    order: 'asc' | 'desc',
    orderBy: keyof T,
): (a: T, b: T) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export const TransactionTable: React.FC<Props> = ({transactions}) => {
    const classes = useTransactionHistoryStyles()

    const [order, setOrder] = useState<'asc' | 'desc'>('desc');
    const [orderBy, setOrderBy] = useState<keyof StrategyTransaction>('deal_date');
    const [showIdColumns, setShowIdColumns] = useState(false);

    const handleRequestSort = (property: keyof StrategyTransaction) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedTransactions = React.useMemo(() => {
        return [...transactions].sort(getComparator(order, orderBy));
    }, [transactions, order, orderBy]);

    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <FormControlLabel
                label="Show ID Columns"
                control={
                    <Checkbox
                        checked={showIdColumns}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setShowIdColumns(event.target.checked)}
                        style={{marginLeft: 10}}
                    />
                }
            />
            <Table className={classes.table} stickyHeader>
                <TransactionTableHeader
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    showIdColumns={showIdColumns}
                />
                <TableBody>
                    {sortedTransactions.map((transaction, idx) => (
                        <TransactionTableRow
                            key={idx}
                            transaction={transaction}
                            showIdColumns={showIdColumns}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};