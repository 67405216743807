import {makeStyles} from "@material-ui/core/styles";

export const useTransactionHistoryStyles = makeStyles((theme) => ({
    dialogTitle: {
        display: 'grid',
        gridTemplateColumns: '48px 1fr 48px',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        margin: 0,
        padding: theme.spacing(2),
    },
    titleText: {
        textAlign: 'center',
        gridColumn: '2',
    },
    closeButton: {
        color: theme.palette.primary.contrastText,
        gridColumn: '3',
        justifySelf: 'end',
    },
    dialogPaper: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '30vh',
        maxHeight: '80vh',
    },
    dialogContent: {
        padding: 0,
        overflow: 'hidden',
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
    },
    loadingText: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(3),
        color: theme.palette.text.secondary,
        fontSize: '1.1rem',
    },
    tableContainer: {
        height: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: theme.palette.grey[100],
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.grey[400],
            borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: theme.palette.grey[500],
        },
    },
    table: {
        minWidth: 650,
    },
    tableHeader: {
        position: 'sticky',
        top: 0,
        backgroundColor: theme.palette.background.paper,
        zIndex: 1,
    },
    statusSuccess: {
        color: theme.palette.success.main,
    },
    statusPending: {
        color: theme.palette.warning.main,
    },
    statusFailed: {
        color: theme.palette.error.main,
    },
    hash: {
        fontFamily: 'monospace',
        fontSize: '0.875rem',
    },
    dialogActions: {
        justifyContent: 'center',
        padding: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.divider}`,
    },
}));