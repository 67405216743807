import React, {useEffect} from "react";
import {Grid} from "@material-ui/core";
import moment from "moment/moment";
import _ from 'lodash';
import DailyChart from "./DailyChart";
import LastDaySummaryCard from "./Summary/LastDaySummaryCard";
import DailyPNLTable from "./DailyPNLTable";
import FilterSection from "./FilterSection";
import DownloadCSV from "./DownloadCSV";
import {IDailyStrategyPNLReportWithChanges, IFilter} from "./DailyStrategyPNLReport";


interface DailyStrategyPNLReportProps {
    data: IDailyStrategyPNLReportWithChanges[];
    selectedFilter: IFilter,
    setSelectedFilter: (newFilter: IFilter) => void;
    setSelectedReport: (report: IDailyStrategyPNLReportWithChanges) => void;
}

const ContractsTab: React.FC<DailyStrategyPNLReportProps> = ({data, selectedFilter, setSelectedFilter,setSelectedReport}) => {
    useEffect(() => {
        if (data.length) {
            const firstItem = data[0];
            setSelectedFilter({
                feesIncluded: false,
                User: selectedFilter.User || firstItem.User,
                Exchange: selectedFilter.Exchange || firstItem.Exchange,
                SymbolId: selectedFilter.SymbolId || firstItem.SymbolId,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const renderLastDaySummary = (filteredData: IDailyStrategyPNLReportWithChanges[]) => {
        const maxDateData = _.maxBy(filteredData, obj => new Date(obj.Date));
        if (!maxDateData) return null;
        return <LastDaySummaryCard maxDateData={maxDateData} symbol={selectedFilter.SymbolId}/>;
    };

    const filteredData = React.useMemo(() => {
        const dataAfterFilter = data.filter((row) => {
            if (selectedFilter.SymbolId && row.SymbolId !== selectedFilter.SymbolId) return false;
            if (selectedFilter.User && row.User !== selectedFilter.User) return false;
            if (selectedFilter.Exchange && row.Exchange !== selectedFilter.Exchange) return false;
            return true;
        })

        return dataAfterFilter.sort((a, b) => moment(b.Date).valueOf() - moment(a.Date).valueOf());
    }, [data, selectedFilter]);


    const isChartEnabled = !!selectedFilter.User && !!selectedFilter.Exchange && !!selectedFilter.SymbolId;

    return (
        <Grid container spacing={1} justify="flex-start" alignItems="flex-start">
            <Grid item xs={6} md={12}>
                <FilterSection
                    data={data}
                    selectedFilter={selectedFilter}
                    onFilterChange={setSelectedFilter}
                />
            </Grid>
            {isChartEnabled && (
                <>
                    <Grid item xs={7} md={9}>
                        <DailyChart data={filteredData} symbol={selectedFilter.SymbolId}
                                    feesIncluded={selectedFilter.feesIncluded}/>
                    </Grid>
                    <Grid item xs={7} md={3} style={{padding: 20}}>{renderLastDaySummary(filteredData)}</Grid>
                </>
            )}
            <Grid item xs={7} md={12}>
                <DailyPNLTable data={filteredData} feesIncluded={selectedFilter.feesIncluded} setSelectedReport={setSelectedReport}/>
            </Grid>
            <Grid item xs={12}>
                <Grid container justify="center">
                    <DownloadCSV
                        data={filteredData}
                        fileName={isChartEnabled ? `${selectedFilter.Exchange}-${selectedFilter.SymbolId}-${selectedFilter.User}` : 'DailyStrategyPnL'}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ContractsTab;
